import React, { useState, useEffect } from 'react';
import { Button } from 'antd';


export default function Footer({layers, onSetLayers}) {

  const onLayersChange = (key) => {
    const data = {...layers}
    data[key] = !data[key]
    onSetLayers(data)
  }

  return (
    <div style={{ display: 'flex', padding: '10px', justifyContent: 'flex-start' }}>
    <Button type="primary" style={{ backgroundColor: '#0077B6' }} onClick={() => onLayersChange("sensors")}>
      Mostrar sensores
    </Button>
    <Button type="primary" style={{ backgroundColor: '#0077B6', marginLeft: '10px' }} onClick={() => onLayersChange("zones")}>
      Mostrar zonas
    </Button>
  </div>
  );
}