import logo from '../logo.svg';
import DroneMap from '../components/DroneMap';
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';

function Home() {
  const [authenticated, setAuthenticated] = useState(undefined);

  const logout = () => {

  }


  return (
    <div>
      <Header/>
      <DroneMap logout={logout} authenticated={authenticated}/>
    </div>
  );
}

export default Home;
