import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default function SidePanel ({sensors, alerts, onSelectSensor, onHandleAlertTracking, selectedSensor, onSelectInhib, inhibitors, selectedInhibitor}) {
    const [showOptions, setShowOptions] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false)
    const [showInhibs, setShowInhibs] = useState(false)

    const toggleOptions = () => {
      setShowOptions(!showOptions);
    };

    // {id: 'DR01602028P4001109', name: 'RF-160 Interior', lat: 41.3956734, lng: 2.1733625, selected: true}

    const toggleAlerts = () => {
        setShowAlerts(!showAlerts)
    }

    const toggleInhibs = () => {
      setShowInhibs(!showInhibs)
    }

    return(
        <div style={{ flex: 1, overflowY: 'auto', height: '100%' }}>
          {selectedSensor !== null && (
            <div style={{ height: "25vh", overflow: 'auto' }}>
              <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "bold", fontSize: 12 }}>
                Nombre: <span style={{ color: '#03045E', fontWeight: "normal", fontSize: 12 }}>{selectedSensor.name}</span>
              </p>
              <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "bold", fontSize: 12 }}>
                Id: <span style={{ color: '#03045E', fontWeight: "normal", fontSize: 12 }}>{selectedSensor.id}</span>
              </p>
              <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "bold", fontSize: 12 }}>
                Latitud: <span style={{ color: '#03045E', fontWeight: "normal", fontSize: 12 }}>{selectedSensor.lat}</span>
              </p>
              <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "bold", fontSize: 12 }}>
                Longitud: <span style={{ color: '#03045E', fontWeight: "normal", fontSize: 12 }}>{selectedSensor.lng}</span>
              </p>
            </div>
          )}
          {selectedInhibitor !== null && (
            <div style={{ height: "25vh", overflow: 'auto' }}>
              <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "bold", fontSize: 12 }}>
                Nombre: <span style={{ color: '#03045E', fontWeight: "normal", fontSize: 12 }}>{selectedInhibitor.name}</span>
              </p>
              <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "bold", fontSize: 12 }}>
                Id: <span style={{ color: '#03045E', fontWeight: "normal", fontSize: 12 }}>{selectedInhibitor.id}</span>
              </p>
              <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "bold", fontSize: 12 }}>
                Latitud: <span style={{ color: '#03045E', fontWeight: "normal", fontSize: 12 }}>{selectedInhibitor.lat}</span>
              </p>
              <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "bold", fontSize: 12 }}>
                Longitud: <span style={{ color: '#03045E', fontWeight: "normal", fontSize: 12 }}>{selectedInhibitor.lng}</span>
              </p>
            </div>
          )}
          <div style={{ flexGrow: 1, overflow: 'auto' }}>
            <p onClick={toggleAlerts} style={{ marginBottom: '10px', backgroundColor: '#90E0EF', color: '#03045E', padding: 8, fontWeight: 'bold' }}>
              <FontAwesomeIcon icon={showAlerts ? faChevronDown : faChevronRight} color='#03045E' />
              Monitoreo
            </p>
            {showAlerts && (
              <div>
                {alerts.map((item) => (
                  <div style={{ backgroundColor: '#CAF0F8', color: "#0077B6", padding: 5 }} onClick={() => onHandleAlertTracking(item.info.alertId)}>
                    {item.info.alertId}
                  </div>
                ))}
              </div>
            )}
            <p onClick={toggleOptions} style={{ marginBottom: '10px', backgroundColor: '#90E0EF', color: '#03045E', padding: 8, fontWeight: 'bold' }}>
              <FontAwesomeIcon icon={showOptions ? faChevronDown : faChevronRight} color='#03045E' />
              Sensores
            </p>
            {showOptions && (
              <div>
                {sensors.map((item) => (
                  <div style={{ backgroundColor: selectedSensor != null && item.id === selectedSensor.id ? '#ffa500' : '#CAF0F8', color: "#0077B6", padding: 5 }} onClick={() => { onSelectSensor(item) }}>
                    {item.name}
                  </div>
                ))}
              </div>
            )}
            <p onClick={toggleInhibs} style={{ marginBottom: '10px', backgroundColor: '#90E0EF', color: '#03045E', padding: 8, fontWeight: 'bold' }}>
              <FontAwesomeIcon icon={showInhibs ? faChevronDown : faChevronRight} color='#03045E' />
              Inhibidores
            </p>
            {showInhibs && (
              <div>
                {inhibitors.map((item) => (
                  <div style={{ backgroundColor: selectedInhibitor != null && item.id === selectedInhibitor.id ? '#ffa500' : '#CAF0F8', color: "#0077B6", padding: 5 }} onClick={() => { onSelectInhib(item) }}>
                    {item.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
    )
}