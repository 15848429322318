import api from "./api";

const AlertServices = {
    getCurrentAlerts: async() => {
        const response = await api({baseURL: '/services/current/dedrone/alerts', method: 'GET'})
        return response
    },
    getAlertById: async (id) => {
        const response = await api({baseURL: `/services/alert?alertId=${id}`, method: "GET"})
        return response
    }
}

export default AlertServices