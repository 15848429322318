import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useAuth } from './providers/AuthContex';
import { useEffect } from 'react';
import SecurityGroups from './pages/SecurityGroups';
import MapEditorScreen from './pages/MapEditor.js/MapEditorScreen';

function App() {
const {accessToken} = useAuth()

  return (
    <BrowserRouter>
      <Routes >
        <Route path="/" element={accessToken ? <Home /> : <Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path='/adm/security-group' element={<SecurityGroups/>}/>
        <Route path='/map-editor' element={<MapEditorScreen/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
