import api from "./api";

const AdminServices = {
    getAllSecurityGroups: async() => {
        const response = await api({baseURL: '/api/v1/config/sec-groups', method: 'GET'})
        return response
    },
    getGroupInfoById: async(id) => {
        const response = await api({baseURL: `/api/v1/config/sec-group?group_id=${id}`, method: 'GET'})
        return response
    },
    createGroup: async(body) => {
        const response = await api({baseURL: '/api/v1/config/sec-groups', method: 'POST', data: body})
        return response
    },
    modifyGroup: async(body) => {
        const response = await api({baseURL: '/api/v1/config/sec-groups', method: 'PUT', data: body})
        return response
    },
    getAvailableUsers: async () => {
        const response = await api({baseURL: '/api/v1/conf/get-available-users', method: 'GET'})
        return response
    },
    linkUserWithGroup: async (body) => {
        const response = await api({baseURL: '/services/link/group/user', method: 'POST', data: body})
        return response
    },
    createNewUser: async (body) => {
        const response = await api({baseURL: '/services/dedrone/create/user', method: 'POST', data: body})
        return response
    },
    modifyUser: async (body) => {
        console.log(body);
        const response = await api({baseURL: '/services/dedrone/update/user', method: 'PUT', data: body})
        return response
    },
    deleteUser: async (id) => {
        const response = await api({baseURL: `/services/delete/user?userid=${id}`, method: 'DELETE'})
        return response
    },
    unlinkUserWithGroup: async (user_id, group_id) => {
        const response = await api({baseURL: `/services/link/group/user?userid=${user_id}&groupid=${group_id}`, method: 'DELETE'})
        return response
    },
    getUserList: async () => {
        const response = await api({baseURL: '/services/SPERTOO/users-list', method: 'GET'})
        return response.data
    },
    unlinkSensorWithGroup: async (sensor_id, group_id) => {
        const response = await api({baseURL: `/services/link/group/sensor?sensorid=${sensor_id}&groupid=${group_id}`, method: 'DELETE'})
        return response
    },
    deleteSensor: async (id) => {
        const response = await api({baseURL: `/api/v1/conf/sensors/${id}`, method: 'DELETE'})
        return response
    },
    getSensorList: async () => {
        const response = await api({baseURL: '/services/SPERTOO/sensors', method: 'GET'})
        return response.data
    },
    unlinkInhibitorWithGroup: async (inhibitor_id, group_id) => {
        const response = await api({baseURL: `/services/link/group/inhibitors?inhibitorid=${inhibitor_id}&groupid=${group_id}`, method: 'DELETE'})
        return response
    },
    deleteInhibitor: async (id) => {
        const response = await api({baseURL: `/api/v1/conf/inhibitors/${id}`, method: 'DELETE'})
        return response
    },
    getInhibitorList: async () => {
        const response = await api({baseURL: '/services/SPERTOO/inhibitors', method: 'GET'})
        return response.data
    },
    deleteGroup: async (id) => {
        const response = await api({baseURL: `/api/v1/config/sec-group?group_id=${id}`, method: 'DELETE'})
        return response
    },
    getDedroneSensors: async () => {
        const response = await api({baseURL: `/services/current/dedrone/sensors`, method: 'GET'})
        return response
    }
}

export default AdminServices