import React from 'react';
import { Button, Input } from 'antd';
import SensorsServices from '../../services/SensorsServices';
import { useAuth } from '../../providers/AuthContex';

export default function MapEditorSidepanel ({
    onAddSensor, 
    selectedSensor, 
    onModifySensor, 
    onCreateNewSensor, 
    onUpdateSensor,
    onDeleteSensor,
    selectedInhib,
    onAddInhib,
    onModifyInhib,
    onCreateNewInhib,
    onUpdateInhib,
    onDeleteInhib,
    sensorButton,
    inhibButton,
    onGetDedroneSensors,
    loading
    }) {
    const {group} = useAuth()

    const modifySensorData = (key, value) => {
        let sensor = {... selectedSensor};
        sensor[key] = value;
        onModifySensor(sensor)
    }

    const modifyInhibData = (key, value) => {
        let inhib = {... selectedInhib};
        console.log("VALUE: ", typeof value);
        inhib[key] = value;
        console.log("INHIB: ", inhib);
        onModifyInhib(inhib)
    }

    const modifyInhibFreq = (key, index ,value) => {
        let inhib = {... selectedInhib};
        inhib[key][index] = value;
        onModifyInhib(inhib)
    }

    const modifyInhibSector = (key, index ,value) => {
        let inhib = {... selectedInhib};
        inhib["sectors"][index][key] = value != "" ? parseInt(value): '';
        onModifyInhib(inhib)
    }

    const inhibAddFreq = () => {
        let inhib = {... selectedInhib};
        inhib['frequencies'].push("");
        onModifyInhib(inhib)
    }

    const inhibRemoveFreq = () => {
        let inhib = {... selectedInhib};
        inhib['frequencies'].pop();
        onModifyInhib(inhib)
    }

    const inhibAddSector = () => {
        let inhib = {... selectedInhib};
        inhib['sectors'].push({id: inhib.sectors.length+1, amplitude: 0, heading: 0, range: 0});
        onModifyInhib(inhib)
    }

    const inhibRemoveSector = () => {
        let inhib = {... selectedInhib};
        inhib['sectors'].pop();
        onModifyInhib(inhib)
    }
    

    return(
        <div style={{ flex: 1, height: '95vh', padding: 4 }}>
        <div>
            <Button type="primary" style={{ backgroundColor: '#0077B6' }} onClick={() => onAddSensor()} loading={loading} disabled={sensorButton || inhibButton || loading}>
                + sensor
            </Button>
            <Button type="primary" style={{ backgroundColor: '#00B4D8', marginLeft: 8 }} onClick={() => onAddInhib()} loading={loading} disabled={sensorButton || inhibButton || loading}>
                + inhibidor
            </Button>
            <Button type="primary" style={{ backgroundColor: '#00B4D8', marginTop: 8 }} onClick={() => onGetDedroneSensors()} loading={loading} disabled={sensorButton || inhibButton || loading}>
                Agregar sensores de Dedrone
            </Button>
        </div>
        <div style={{ marginTop: 16, height: 'calc(95vh - 60px)', overflowY: 'auto' }}> {/* Adjust height as needed */}
            {selectedSensor != null && 
                <div style={{ marginBottom: 16 }}>
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Nombre del sensor
                    </p>
                    <Input 
                        placeholder='Nombre del sensor'
                        value={selectedSensor.name} 
                        onChange={(e) => { modifySensorData("name", e.target.value) }}
                    />
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        UUID del sensor
                    </p>
                    <Input 
                        placeholder='UUID del sensor'
                        value={selectedSensor.id} 
                        disabled={!selectedSensor.new}
                        onChange={(e) => { modifySensorData("newId", e.target.value) }}
                    />
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Latitud
                    </p>
                    <Input 
                        placeholder='Latitud'
                        value={selectedSensor.position.lat} 
                        onChange={(e) => { modifySensorData("lat", e.target.value) }}
                    />
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Longitud
                    </p>
                    <Input 
                        placeholder='Longitud'
                        value={selectedSensor.position.lng} 
                        onChange={(e) => { modifySensorData("lng", e.target.value) }}
                    />
                    <Button 
                        type="primary" 
                        loading={loading}
                        style={{ backgroundColor: '#0077B6', marginTop: 8, marginRight: 8 }} 
                        disabled={selectedSensor.name === "" || selectedSensor.id === ""}
                        onClick={() => { selectedSensor.new ? onCreateNewSensor() : onUpdateSensor() }}>
                        {selectedSensor.new ? "Crear sensor" : "Guardar sensor"}
                    </Button>
                    <Button 
                        type="primary" 
                        loading={loading}
                        style={{ backgroundColor: '#EE4B2B', marginTop: 8 }} 
                        onClick={() => { onDeleteSensor() }}>
                        {"Borrar sensor"}
                    </Button>
                </div>
            }
            {selectedInhib != null && 
                <div style={{ marginBottom: 16 }}>
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Nombre del inhibidor
                    </p>
                    <Input 
                        placeholder='Nombre del inhibidor'
                        value={selectedInhib.inhibitorname} 
                        onChange={(e) => { modifyInhibData("inhibitorname", e.target.value) }}
                    />
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        UUID del inhibidor
                    </p>
                    <Input 
                        placeholder='UUID del inhibidor'
                        value={selectedInhib.id} 
                        disabled={!selectedInhib.new}
                        onChange={(e) => { modifyInhibData("newId", e.target.value) }}
                    />
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Latitud
                    </p>
                    <Input 
                        placeholder='Latitud'
                        value={selectedInhib.position.lat} 
                        onChange={(e) => { modifyInhibData("lat", e.target.value) }}
                    />
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Longitud
                    </p>
                    <Input 
                        placeholder='Longitud'
                        value={selectedInhib.position.lng} 
                        onChange={(e) => { modifyInhibData("lng", e.target.value) }}
                    />
                    {/* <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Heading
                    </p>
                    <Input 
                        placeholder='Heading'
                        value={selectedInhib.heading} 
                        onChange={(e) => { modifyInhibData("heading", e.target.value) }}
                    /> */}
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Frecuencias <Button style={{height:16, width:8}} type='primary' onClick={() => { inhibAddFreq()}}>{"+"}</Button> {selectedInhib.frequencies.length > 1 && <Button style={{height:16, width:8}} type='primary' onClick={() => { inhibRemoveFreq()}}>{"-"}</Button>}
                    </p>
                    {selectedInhib.frequencies.map((e, index) => (
                        <Input 
                            key={index} // Don't forget to add a key for each item in a list
                            style={{ marginBottom: 4 }}
                            placeholder='Frecuencia'
                            value={e} 
                            onChange={(e) => { modifyInhibFreq("frequencies", index, e.target.value) }}
                        />
                    ))}
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Sectores <Button style={{height:16, width:8}} type='primary' onClick={() => { inhibAddSector()}}>{"+"}</Button> {selectedInhib.sectors.length > 1 &&<Button style={{height:16, width:8}} type='primary' onClick={() => { inhibRemoveSector()}}>{"-"}</Button>}
                    </p>
                    {selectedInhib.sectors.map((e, index) => (
                        <div>
                            <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "normal", fontSize: 14 }}>
                                {`Sector ${index+1}`}
                            </p>
                            <div>
                                <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "normal", fontSize: 12 }}>
                                    Amplitud
                                </p>
                                <Input 
                                    key={index+"amp"} // Don't forget to add a key for each item in a list
                                    style={{ marginBottom: 4 }}
                                    placeholder='Amplitud'
                                    value={e.amplitude} 
                                    onChange={(e) => { modifyInhibSector("amplitude", index, e.target.value) }}
                                />
                                <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "normal", fontSize: 12 }}>
                                    Heading
                                </p>
                                <Input 
                                    key={index+"head"} // Don't forget to add a key for each item in a list
                                    style={{ marginBottom: 4 }}
                                    placeholder='Heading'
                                    value={e.heading} 
                                    onChange={(e) => { modifyInhibSector("heading", index, e.target.value) }}
                                />
                                <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "normal", fontSize: 12 }}>
                                    Rango
                                </p>
                                <Input 
                                    key={index+"rng"} // Don't forget to add a key for each item in a list
                                    style={{ marginBottom: 4 }}
                                    placeholder='Rango'
                                    value={e.range} 
                                    onChange={(e) => { modifyInhibSector("range", index, e.target.value) }}
                                />
                            </div>
                        </div>
                    ))}
                    <Button 
                        type="primary" 
                        loading={loading}
                        style={{ backgroundColor: '#0077B6', marginTop: 8, marginRight: 8 }} 
                        disabled={selectedInhib.inhibitorname === "" || selectedInhib.id === ""}
                        onClick={() => { selectedInhib.new ? onCreateNewInhib() : onUpdateInhib() }}>
                        {selectedInhib.new ? "Crear inhibidor" : "Guardar inhibidor"}
                    </Button>
                    <Button 
                        type="primary" 
                        loading={loading}
                        style={{ backgroundColor: '#EE4B2B', marginTop: 8 }} 
                        onClick={() => { onDeleteInhib() }}>
                        {"Borrar inhibidor"}
                    </Button>
                </div>
            }
        </div>
    </div>
    )
}

// {
//     "inhibitorid": "bd9677e0-c0d1-499e-ab08-2b17dfdac552", 
//     "inhibitorname": "test", 
//     "latitude": 15.0123, 
//     "longitude": 9.023, 
//     "heading": 0, 
//     "frequencies": ["a", "b"],
//     "sectors": [{"id": 1, "range": 50, "heading": 90, "amplitude": 10}, {"id": 2, "range": 60, "heading": 180, "amplitude": 15}], 
//     "manufacturer": "test"
// }