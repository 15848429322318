import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: "https://keycloak.squadrone.es/",
    realm: "SPERTOO",
    clientId: 'react-client'
  }
  
const keycloakGlobal = new Keycloak(keycloakConfig);

export default keycloakGlobal