import React from 'react'
import Header from '../../components/Header'
import MapEditorComponent from '../../components/Maps/MapEditorComponent'

export default function MapEditorScreen() {
  return (
    <div>
      <Header/>
      <MapEditorComponent/>
    </div>
  )
}