import React from "react";
import { Marker } from "@vis.gl/react-google-maps";
import { Circle } from "./InhibCircle.ts";
import InhibitorIcons from "../SVG-icons/InhibitorIcons.js";

const InhibMarker = ({inhib, onInhibDragEnd, onSelectedInhib, selectedInhib}) => {

    return(
        <>
            {/* <Circle
                radius={4500}
                center={inhib.position}
                strokeColor={'black'}
                strokeOpacity={1}
                strokeWeight={3}
                fillColor={'transparent'}
                fillOpacity={0.3}
                concentricCount={5}
            />
            <Circle
                radius={4500*0.75}
                center={inhib.position}
                strokeColor={'black'}
                strokeOpacity={1}
                strokeWeight={3}
                fillColor={'transparent'}
                fillOpacity={0.3}
                concentricCount={5}
            />
            <Circle
                radius={4500*0.50}
                center={inhib.position}
                strokeColor={'black'}
                strokeOpacity={1}
                strokeWeight={3}
                fillColor={'transparent'}
                fillOpacity={0.3}
                concentricCount={5}
            />
            <Circle
                radius={4500*0.25}
                center={inhib.position}
                strokeColor={'black'}
                strokeOpacity={1}
                strokeWeight={3}
                fillColor={'transparent'}
                fillOpacity={0.3}
                concentricCount={5}
            /> */}
            <Marker
                key={inhib.id}
                position={inhib.position}
                draggable={true}
                onDragEnd={(event) => onInhibDragEnd(event, inhib.id)}
                onClick={() => {onSelectedInhib(inhib);}}
                icon={inhib.id === selectedInhib?.id ? InhibitorIcons.selectedInhibIcon : inhib.new ? InhibitorIcons.newInhibIcon : InhibitorIcons.defaultInhibIcon}
            />
        </>
    )
}

export default InhibMarker