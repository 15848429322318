import React, { createContext, useState, useContext } from 'react';

// Create the context
const AuthContext = createContext(null);

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [group, setGroup] = useState(null)

  // Function to log in
  const login = (token, refresh) => {
    //cambiar a cookies
    // API.js
    // localStorage.setItem("token", token)
    document.cookie = `a_token=${token}; path=/login`
    document.cookie = `r_token=${refresh}; path=/login`
    setAccessToken(token);
  };

  // Function to log out
  const logout = (token, refresh) => {
    // localStorage.setItem("token", null)
    document.cookie = `a_token=${token}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/login;`
    document.cookie = `r_token=${refresh}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/login;`
    setAccessToken(null);
    setGroup(null)
  };

  const saveGroup = (group) => {
    setGroup(group)
  }

  return (
    <AuthContext.Provider value={{ accessToken, login, logout, group, saveGroup }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};