import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import { Button, Flex, Table, Modal, Input, Select, message, Tabs} from 'antd';
import AdminServices from '../services/AdminServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import InhibitorServices from '../services/InhibitorServices';
import SensorsServices from '../services/SensorsServices';

function SecurityGroups() {
    const { Option } = Select;
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [groups, setGroups] = useState([])
    const [userList, setUserList] = useState([])
    const [sensorList, setSensorList] = useState([])
    const [inhibitorList, setInhibitorList] = useState([])
    const [newGroupName, setNewGroupName] = useState("")
    const [pageLoading, setPageLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newUserModal, setNewUserModal] = useState(false)
    const [newUserData, setNewUserData] = useState({
        "username": "",
        "email": "",
        "pass": "",
        "role": "",
        "language": ""
    })
    const [newSensorData, setNewSensorData] = useState({id: "", name: "", lat: "", lng: ""})
    const [newInhibitorData, setNewInhibitorData] = useState({
        id: "", 
        inhibitorname: "", 
        lat: 0,
        lng: 0,
        heading: 0, 
        frequencies:[''],
        sectors:[{id: 1, amplitude: 0, heading: 0, range: 0}],
        manufacturer: '',
    })
    const [newSensorModal, setNewSensorModal] = useState(false)
    const [newInhibitorModal, setNewInhibitorModal] = useState(false)
    const [newUserLoading, setNewUserLoading] = useState(false)
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [groupDataModal, setGroupDataModal] = useState({group_name: undefined, user_list: []})
    const [createGroupLoading, setCreateGroupLoading] = useState(false)
    const [addUserModal, setAddUserModal] = useState(false)
    const [addSensorModal, setAddSensorModal] = useState(false)
    const [addInhibitorModal, setAddInhibitorModal] = useState(false)
    const [availableUsers, setAvailableUsers] = useState([])
    const [selectedUserToAdd, setSelectedUserToAdd] = useState("")
    const [activeKey, setActiveKey] = useState('1');
    const [modalActiveTab, setModalActiveTab] = useState('1');
    const [groupDataLoading, setGroupDataLoading] = useState(false);
    const [editGroup, setEditGroup] = useState({name: null})
    const [editUser, setEditUser] = useState({
        "name": "",
        "email": "",
        "pass": "",
        "role": "",
        "language": ""
    })
    const { TabPane } = Tabs;

    useEffect(() => {
        getSecurityGroups()
    }, []);

    const columns = [
        {
        title: 'Grupo de seguridad',
        dataIndex: 'name',
        key: 'name',
        render: (text, item) => <a onClick={()=>{showGroupDataModal(item.id);}}>{text}</a>,
        },
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (e) => {return(
                <>
                    {pageLoading || (!userList.some(obj => obj.group === e.name) && !sensorList.some(obj => obj.group === e.name) && !inhibitorList.some(obj => obj.group === e.name)) &&
                    <a onClick={()=> deleteGroup(e.id)} style={{marginRight: 8}}>
                        <FontAwesomeIcon color='red' icon={faTrash} />
                    </a>}
                    <a onClick={()=> setEditGroup({id: e.id, name: e.name})}>
                        <FontAwesomeIcon color='#0077B6' icon={faPen} />
                    </a>
                </>
            )}
        },
    ];

    const userColumn = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (e) => {return(
                <a onClick={()=> unlinkUserWithGroup(e.id)}>
                    <FontAwesomeIcon color='red' icon={faTrash} />
                </a>
            )}
        },
    ]

    const createTableFilterUsers = () => {
        let filters = [...new Set(groups)].map(group => ({ text: group.name, value: group.name }))
        filters.push({text: "Sin grupo", value: null })
        return  filters
    }

    const availableUserColumn = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            filters:createTableFilterUsers(),
            onFilter: (value, record) => record.group === value,
        },
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (e) => {return(
            <>
                {e.group === null && 
                <a onClick={()=> deleteUser(e.id)} style={{marginRight: 8}}>
                    <FontAwesomeIcon color='red' icon={faTrash} />
                </a>}
                <a onClick={()=> setEditUser(e)}>
                    <FontAwesomeIcon color='#0077B6' icon={faPen} />
                </a>
            </>
        )}
        },
    ]

    const availableSensorColumns = [
        {
            title: 'Nombre',
            dataIndex: 'sensorName',
            key: 'sensorName',
        },
        {
            title: 'Id',
            dataIndex: 'sensorId',
            key: 'sensorId',
        },
        {
            title: 'Lat',
            dataIndex: 'latitude',
            key: 'latitude',
        },
        {
            title: 'Long',
            dataIndex: 'longitude',
            key: 'longitude',
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            filters:createTableFilterUsers(),
            onFilter: (value, record) => record.group === value,
        },
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (e) => {return(
                e.group === null && 
                <a onClick={()=> deleteSensor(e.sensorId)}>
                    <FontAwesomeIcon color='red' icon={faTrash} />
                </a>
            )}
        },
    ]

    const availableInhibitorColumns = [
        {
            title: 'Nombre',
            dataIndex: 'inhibitorname',
            key: 'inhibitorname',
        },
        {
            title: 'Id',
            dataIndex: 'inhibitorid',
            key: 'inhibitorid',
        },
        {
            title: 'Lat',
            dataIndex: 'latitude',
            key: 'latitude',
        },
        {
            title: 'Long',
            dataIndex: 'longitude',
            key: 'longitude',
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            filters:createTableFilterUsers(),
            onFilter: (value, record) => record.group === value,
        },
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (e) => {return(
                e.group === null && 
                <a onClick={()=> deleteInhibitor(e.inhibitorid)}>
                    <FontAwesomeIcon color='red' icon={faTrash} />
                </a>
            )}
        },
    ]

    const sensorColumns = [
        {
            title: 'Nombre',
            dataIndex: 'sensorName',
            key: 'sensorName',
        },
        {
            title: 'Id',
            dataIndex: 'sensorId',
            key: 'sensorId',
        },
        {
            title: 'Lat',
            dataIndex: 'latitude',
            key: 'latitude',
        },
        {
            title: 'Long',
            dataIndex: 'longitude',
            key: 'longitude',
        },
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (e) => {return(
                <a onClick={()=> unlinkSensorWithGroup(e.sensorId)}>
                    <FontAwesomeIcon color='red' icon={faTrash} />
                </a>
            )}
        },
    ]

    const inhibitorColumns = [
        {
            title: 'Nombre',
            dataIndex: 'inhibitorname',
            key: 'inhibitorname',
        },
        {
            title: 'Id',
            dataIndex: 'inhibitorid',
            key: 'inhibitorid',
        },
        {
            title: 'Lat',
            dataIndex: 'latitude',
            key: 'latitude',
        },
        {
            title: 'Long',
            dataIndex: 'longitude',
            key: 'longitude',
        },
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (e) => {return(
                <a onClick={()=> unlinkInhibitorWithGroup(e.inhibitorid)}>
                    <FontAwesomeIcon color='red' icon={faTrash} />
                </a>
            )}
        },
    ]

    const roles = [
        "Admin",
        "Configurator",
        "Mitigation_Operator",
        "Operator",
        "Viewer"
    ]

    const langauges = [
        "español",
        "català"
    ]

    const deleteGroup = async(id) => {
        setPageLoading(true)
        try {
            const response = await AdminServices.deleteGroup(id)
            messageApi.open({
                type: 'success',
                content: 'Grupo eliminado con exito',
            });
            getSecurityGroups()
        } catch (error) {
            console.log("deleteGroup error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
            setPageLoading(false)
        }
    }

    const getSecurityGroups = async() => {
        setPageLoading(true)
        try {
            const response = await AdminServices.getAllSecurityGroups()
            if(response.status != 200){
                navigate("/")
            }
            setGroups(response.data.data)
            getAvailableUsers()
        } catch (error) {
            console.log("getSecurityGroups error: ", error);
            navigate("/")
            setPageLoading(false)
        }
    }

    const getGroupById = async(id) => {
        setGroupDataLoading(true)
        try {
            const response = await AdminServices.getGroupInfoById(id)
            console.log(response.data);
            setGroupDataModal(response.data)
        } catch (error) {
            console.log("getGroupById error: ", error);
        }finally{
            setGroupDataLoading(false)
        }
    }

    const getUserList = async() => {
        try {
            const response = await AdminServices.getUserList()
            setUserList(response.data)
            getSensorList()
        } catch (error) {
            console.log("getUserList error: ", error);
            setPageLoading(false)
        }
    }

    const getSensorList = async() => {
        try {
            const response = await AdminServices.getSensorList()
            setSensorList(response.data)
            getInhibitorList()
        } catch (error) {
            console.log("getSensorList error: ", error);
            setPageLoading(false)
        }
    }

    const getInhibitorList = async() => {
        try {
            const response = await AdminServices.getInhibitorList()
            setInhibitorList(response.data)
            setPageLoading(false)
        } catch (error) {
            console.log("getSensorList error: ", error);
            setPageLoading(false)
        }  
    }

    const getAvailableUsers = async () => {
        try {
            const response = await AdminServices.getAvailableUsers()
            getUserList()
            setAvailableUsers(response.data)
        } catch (error) {
            console.log("getAvailableUsers error: ", error);
            setPageLoading(false)
        }
    }

    const createGroup = async () => {
        setCreateGroupLoading(true)
        try {
            const response = await AdminServices.createGroup({name: newGroupName})
            setNewGroupName("")
            setIsModalOpen(false);
            getSecurityGroups()
        } catch (error) {
            console.log("createGroup error: ", error);
        } finally {
            setCreateGroupLoading(false)
        }
    }

    const addUserToGroup = async () => {
        try {
            const response = await AdminServices.linkUserWithGroup({group_id: groupDataModal.group_id, user_id: selectedUserToAdd})
            if(response.data.message == "User already in a group"){
                messageApi.open({
                    type: 'error',
                    content: 'El sensor ya pertenece al grupo',
                });
            } else {
                messageApi.open({
                    type: 'success',
                    content: 'Sensor agregado al grupo',
                });
                setAddUserModal(false)
                setSelectedUserToAdd("")
                setShowGroupModal(true);
                getGroupById(groupDataModal.group_id)
                getSecurityGroups()
            }
        } catch (error) {
            console.log("addUserToGroup error: ", error);
        }
    }

    const addSensorToGroup = async() => {
        try {
            const response = await SensorsServices.linkSensorWithGroup({group_id: groupDataModal.group_id, sensor_id: selectedUserToAdd})
            if(response.data.message == "Link already exists"){
                messageApi.open({
                    type: 'error',
                    content: 'El sensor ya pertenece al grupo',
                });
            } else {
                messageApi.open({
                    type: 'success',
                    content: 'Sensor agregado al grupo',
                });
                setAddSensorModal(false)
                setSelectedUserToAdd("")
                setShowGroupModal(true);
                getGroupById(groupDataModal.group_id)
                getSecurityGroups()
            }
        } catch (error) {
            console.log("addInhitorToGroup error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        }    
    }

    const addInhitorToGroup = async () => {
        try {
            const response = await InhibitorServices.linkInhibitorWithGroup({group_id: groupDataModal.group_id, inhibitor_id: selectedUserToAdd})
            if(response.data.message == "Link already exists"){
                messageApi.open({
                    type: 'error',
                    content: 'El inhibidor ya pertenece al grupo',
                });
            } else {
                messageApi.open({
                    type: 'success',
                    content: 'Inhibidor agregado al grupo',
                });
                setAddInhibitorModal(false)
                setSelectedUserToAdd("")
                setShowGroupModal(true);
                getGroupById(groupDataModal.group_id)
                getSecurityGroups()
            }
        } catch (error) {
            console.log("addInhitorToGroup error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        }      
    }

    const handleCreateUser = async () => {
        if(newUserData.username === "" ||
            newUserData.email === "" ||
            newUserData.pass === "" ||
            newUserData.role=== "" ||
            newUserData.language === ""
        ){
            messageApi.open({
                type: 'error',
                content: 'Complete todos los campos',
                });
            return
            }
        setNewUserLoading(true)
        try {
            const response = await AdminServices.createNewUser(newUserData)
            if(response.status == 200){
                messageApi.open({
                    type: 'success',
                    content: 'Usuario creado con exito',
                });
                setNewUserModal(false)
                getSecurityGroups()
            }
        } catch (error) {
            console.log("hancdleCreateUser error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        } finally {
            setNewUserLoading(false)
        }
    }

    const modifyUser = async () => {
        if(editUser.name === "" ||
            editUser.email === "" ||
            editUser.pass === "" ||
            editUser.role=== "" ||
            editUser.language === ""
        ){
            messageApi.open({
                type: 'error',
                content: 'Complete todos los campos',
                });
            return
            }
        setNewUserLoading(true)
        try {
            const response = await AdminServices.modifyUser({...editUser, username: editUser.name})
            if(response.status == 200){
                messageApi.open({
                    type: 'success',
                    content: 'Usuario modificado con exito',
                });
                setEditUser({        
                    "name": "",
                    "email": "",
                    "pass": "",
                    "role": "",
                    "language": ""
                })
                getSecurityGroups()
            }
        } catch (error) {
            console.log("modifyUser error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        } finally {
            setNewUserLoading(false)
        }
    }

    const deleteUser = async (id) => {
        setPageLoading(true)
        try {
            const response = await AdminServices.deleteUser(id)
            messageApi.open({
                type: 'success',
                content: 'Usuario eliminado con exito',
            });
            getSecurityGroups()
        } catch (error) {
            console.log("deleteUser error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
            setPageLoading(false)
        } 
    }

    const modifyGroup = async () => {
        console.log({name: editGroup.name, group_id: editGroup.id});
        setCreateGroupLoading(true)
        try {
            const response = await AdminServices.modifyGroup({name: editGroup.name, group_id: editGroup.id})
            setEditGroup({name: null})
            getSecurityGroups()
        } catch (error) {
            console.log("createGroup error: ", error);
        } finally {
            setCreateGroupLoading(false)
        }
    }

    const unlinkUserWithGroup = async (id) => {
        try {
            const response = await AdminServices.unlinkUserWithGroup(id, groupDataModal.group_id)
            messageApi.open({
                type: 'success',
                content: 'Usuario desvinculado con exito',
            });
            getSecurityGroups()
            getGroupById(groupDataModal.group_id)
        } catch (error) {
            console.log("unlinkUserWithGroup error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        }
    }

    const deleteSensor = async (id) => {
        setPageLoading(true)
        try {
            const response = await AdminServices.deleteSensor(id)
            messageApi.open({
                type: 'success',
                content: 'Sensor eliminado con exito',
            });
            getSecurityGroups()
        } catch (error) {
            console.log("deleteUser error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
            setPageLoading(false)
        } 
    }

    const unlinkSensorWithGroup = async (id) => {
        try {
            const response = await AdminServices.unlinkSensorWithGroup(id, groupDataModal.group_id)
            messageApi.open({
                type: 'success',
                content: 'Sensor desvinculado con exito',
            });
            // setShowGroupModal(false);
            // setGroupDataModal({group_name: undefined, user_list: []})
            getSecurityGroups()
            getGroupById(groupDataModal.group_id)
        } catch (error) {
            console.log("unlinkSensorWithGroup error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        }
    }

    const unlinkInhibitorWithGroup = async (id) => {
        try {
            const response = await AdminServices.unlinkInhibitorWithGroup(id, groupDataModal.group_id)
            messageApi.open({
                type: 'success',
                content: 'Grupo modificado con exito',
            });
            // setShowGroupModal(false);
            // setGroupDataModal({group_name: undefined, user_list: []})
            getSecurityGroups()
            getGroupById(groupDataModal.group_id)
        } catch (error) {
            console.log("unlinkInhibitorWithGroup error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        }
    }

    const deleteInhibitor = async (id) => {
        setPageLoading(true)
        try {
            const response = await AdminServices.deleteInhibitor(id)
            messageApi.open({
                type: 'success',
                content: 'Sensor eliminado con exito',
            });
            getSecurityGroups()
        } catch (error) {
            console.log("deleteUser error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
            setPageLoading(false)
        } 
    }

    const createNewSensor = async () => {
        if(newSensorData.id === "" ||
            newSensorData.name === "" ||
            newSensorData.lat === "" ||
            newSensorData.lng=== ""){
            messageApi.open({
                type: 'error',
                content: 'Complete todos los campos',
                });
            return
            }
        setNewUserLoading(true)
        try {
            let body = {
                sensor_id: newSensorData.id,
                manufacturer: "",
                sensor_name: newSensorData.name,
                sensor_state: 'active',
                lat: newSensorData.lat,
                long: newSensorData.lng,
                recived_time: new Date().toISOString().slice(0, 19).replace('T', ' ')
            }
            const response = await SensorsServices.postSensor(body)
            if(response.status == 200){
                messageApi.open({
                    type: 'success',
                    content: 'Sensor creado con exito',
                });
                setNewSensorModal(false)
                setNewSensorData({id: "", name: "", lat: "", lng: ""})
                getSecurityGroups()
            }
        } catch (error) {
            console.log("hancdleCreateUser error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        } finally {
            setNewUserLoading(false)
        }
    }

    const createNewInhibitor = async () => {
        if(newInhibitorData.id === "" ||
            newInhibitorData.lat === "" ||
            newInhibitorData.lng=== ""||
            newInhibitorData.inhibitorname === ""
        ){
            messageApi.open({
                type: 'error',
                content: 'Complete todos los campos',
                });
            return
            }
        setNewUserLoading(true)
        try {
            let body = {
                inhibitorid: newInhibitorData.id,
                inhibitorname: newInhibitorData.inhibitorname,
                latitude: newInhibitorData.lat,
                longitude: newInhibitorData.lng,
                heading: 0,
                frequencies: newInhibitorData.frequencies,
                sectors: newInhibitorData.sectors,
                manufacturer: newInhibitorData.manufacturer
              }
            const response = await InhibitorServices.postInhibitors(body)
            if(response.status == 200){
                messageApi.open({
                    type: 'success',
                    content: 'Inhibidor creado con exito',
                });
                setNewInhibitorModal(false)
                setNewInhibitorData({        
                    id: "", 
                    inhibitorname: "", 
                    lat: 0,
                    lng: 0,
                    heading: 0, 
                    frequencies:[''],
                    sectors:[{id: 1, amplitude: 0, heading: 0, range: 0}],
                    manufacturer: '',})
                getSecurityGroups()
            }
        } catch (error) {
            console.log("hancdleCreateUser error: ", error);
            messageApi.open({
                type: 'error',
                content: 'Algo salio mal intentelo luego',
            });
        } finally {
            setNewUserLoading(false)
        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        createGroup()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showGroupDataModal = (id) => {
        setShowGroupModal(true)
        getGroupById(id)
    }

    const handleAddMemberModal = () => {
        setShowGroupModal(false);
        setAddUserModal(true)
    }

    const handleAddInhibitorModal = () => {
        setShowGroupModal(false)
        setAddInhibitorModal(true)
    }

    const handleAddSensorModal = () => {
        setShowGroupModal(false)
        setAddSensorModal(true)
    }

    const handleAddGroupMember = () => {
        addUserToGroup()
    };

    const handleAddSensorToGroup = () => {
        addSensorToGroup()
    }
    
    const handleAddInhibitorToGroup = () => {
        addInhitorToGroup()
    }

    const handleCancelGroupDataModal = () => {
        setShowGroupModal(false);
        setGroupDataModal({group_name: undefined, user_list: []})
    };

    const closeAddModal = () => {
        setShowGroupModal(true);
        setAddUserModal(false)
        setAddInhibitorModal(false)
        setAddSensorModal(false)
    }

    const handleSelectUserToAdd = (item) => {
        setSelectedUserToAdd(item);
    }

    const handleChangeUserData = (key, value) => {
        const userData = {...newUserData}
        userData[key] = value
        setNewUserData(userData)
    }

    const handleChangeEditUserData = (key, value) => {
        const userData = {...editUser}
        userData[key] = value
        setEditUser(userData)
    }

    const handleChangeSensorData = (key, value) => {
        const sensorData = {...newSensorData}
        sensorData[key] = value
        setNewSensorData(sensorData)
    }

    const modifyInhibData = (key, value) => {
        let inhib = {... newInhibitorData};
        inhib[key] = value;
        setNewInhibitorData(inhib)
    }

    const modifyInhibFreq = (key, index ,value) => {
        let inhib = {... newInhibitorData};
        inhib[key][index] = value;
        setNewInhibitorData(inhib)
    }

    const modifyInhibSector = (key, index ,value) => {
        let inhib = {... newInhibitorData};
        inhib["sectors"][index][key] = value != "" ? parseInt(value): '';
        setNewInhibitorData(inhib)
    }

    const inhibAddFreq = () => {
        let inhib = {... newInhibitorData};
        inhib['frequencies'].push("");
        setNewInhibitorData(inhib)
    }

    const inhibAddSector = () => {
        let inhib = {... newInhibitorData};
        inhib['sectors'].push({id: inhib.sectors.length+1, amplitude: 0, heading: 0, range: 0});
        setNewInhibitorData(inhib)
    }

    const handleCancelCreateUser = () => {
        setNewUserModal(false)
        setNewUserData({    
            "username": "",
            "email": "",
            "pass": "",
            "role": "",
            "langauge": ""
        })
    }

    const handleCancelCreateSensor = () => {
        setNewSensorModal(false)
        setNewSensorData({id: "", name: "", lat: "", lng: ""})
    }

    const handleCancelCreateInhib = () => {
        setNewInhibitorModal(false)
        setNewInhibitorData({
            id: "", 
            inhibitorname: "", 
            lat: 0,
            lng: 0,
            heading: 0, 
            frequencies:[''],
            sectors:[{id: 1, amplitude: 0, heading: 0, range: 0}],
            manufacturer: '',
        })
    }

  return (
    <div>
        <Header/>
        <div style={{padding: 8}}>
            {contextHolder}
            <Flex align="center" gap="middle">
                <Button type="primary" style={{backgroundColor:'#0077B6'}} onClick={() => showModal()}>
                    Crear grupo de seguridad
                </Button>
                <Button type="primary" style={{backgroundColor:'#0077B6'}} onClick={() => setNewUserModal(true)}>
                    Crear usuario
                </Button>
                {/* <Button type="primary" style={{backgroundColor:'#0077B6'}} onClick={() => setNewSensorModal(true)}>
                    Crear sensor
                </Button>
                <Button type="primary" style={{backgroundColor:'#0077B6'}} onClick={() => setNewInhibitorModal(true)}>
                    Crear inhibidor
                </Button> */}
            </Flex>
            <Tabs activeKey={activeKey} onChange={setActiveKey}>
                <TabPane tab="Grupos" key="1">
                    <Table  columns={columns} dataSource={groups} loading={pageLoading}/>
                </TabPane>
                <TabPane tab="Usuarios" key="2">
                    <Table  columns={availableUserColumn} dataSource={userList} loading={pageLoading}/>
                </TabPane>
                <TabPane tab="Sensores" key="3">
                    <Table  columns={availableSensorColumns} dataSource={sensorList} loading={pageLoading}/>
                </TabPane>
                <TabPane tab="Inhibidores" key="4">
                    <Table  columns={availableInhibitorColumns} dataSource={inhibitorList} loading={pageLoading}/>
                </TabPane>
            </Tabs>

            {/* MODAL CREAR USUARIO NUEVO */}
            <Modal 
                title={"Crear usuario nuevo"}
                open={newUserModal} 
                okText={"Crear usuario"}
                onOk={handleCreateUser} 
                okButtonProps={{ disabled: newUserLoading }}
                cancelText={"Cerrar"}
                onCancel={handleCancelCreateUser} 
                loading={false}>
                <Input 
                    style={{marginBottom: 8}}
                    placeholder='Nombre del usuario'
                    value={newUserData.username} 
                    onChange={(e) => {handleChangeUserData("username", e.target.value);}}
                />
                <Input 
                    style={{marginBottom: 8}}
                    placeholder='Correo del usuario'
                    value={newUserData.email} 
                    onChange={(e) => {handleChangeUserData("email", e.target.value);}}
                />
                <Input 
                    style={{marginBottom: 8}}
                    placeholder='Contraseña del usuario'
                    value={newUserData.pass} 
                    onChange={(e) => {handleChangeUserData("pass", e.target.value);}}
                />
                <Select
                    placeholder="Selecciona un rol"
                    style={{width: '100%', marginBottom: 8}}
                    onChange={(e)=>{handleChangeUserData("role", e);}}
                    >
                    {roles.map(item => (
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
                <Select
                    placeholder="Selecciona un idoma"
                    style={{width: '100%'}}
                    onChange={(e)=>{handleChangeUserData("language", e);}}
                    >
                    {langauges.map(item => (
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Modal>

            {/* MODAL EDITAR USUARIO */}
            <Modal 
                title={"Editar usuario"}
                open={editUser.name !== ""} 
                okText={"Editar usuario"}
                onOk={modifyUser} 
                okButtonProps={{ disabled: newUserLoading }}
                cancelText={"Cerrar"}
                onCancel={() => {setEditUser(({    
                    "name": "",
                    "email": "",
                    "pass": "",
                    "role": "",
                    "language": ""
                }))}} 
                loading={false}>
                <Input 
                    style={{marginBottom: 8}}
                    placeholder='Nombre del usuario'
                    value={editUser.name} 
                    onChange={(e) => {handleChangeEditUserData("name", e.target.value);}}
                />
                <Input 
                    style={{marginBottom: 8}}
                    placeholder='Correo del usuario'
                    value={editUser.email} 
                    onChange={(e) => {handleChangeEditUserData("email", e.target.value);}}
                />
                <Input 
                    style={{marginBottom: 8}}
                    placeholder='Contraseña del usuario'
                    value={editUser.pass} 
                    onChange={(e) => {handleChangeEditUserData("pass", e.target.value);}}
                />
                <Select
                    placeholder="Selecciona un rol"
                    style={{width: '100%', marginBottom: 8}}
                    value={editUser.role}
                    onChange={(e)=>{handleChangeEditUserData("role", e);}}
                    >
                    {roles.map(item => (
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
                <Select
                    placeholder="Selecciona un idoma"
                    style={{width: '100%'}}
                    value={editUser.language}
                    onChange={(e)=>{handleChangeEditUserData("language", e);}}
                    >
                    {langauges.map(item => (
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Modal>

            {/* MODAL CREAR SENSOR NUEVO */}
            <Modal 
                title={"Crear sensor nuevo"}
                open={newSensorModal} 
                okText={"Crear sensor"}
                onOk={createNewSensor} 
                okButtonProps={{ disabled: newUserLoading }}
                cancelText={"Cerrar"}
                onCancel={handleCancelCreateSensor} 
                loading={false}>
                <Input 
                    style={{marginBottom: 8}}
                    placeholder='Id'
                    value={newSensorData.id} 
                    onChange={(e) => {handleChangeSensorData("id", e.target.value);}}
                />
                <Input 
                    style={{marginBottom: 8}}
                    placeholder='Nombre'
                    value={newSensorData.name} 
                    onChange={(e) => {handleChangeSensorData("name", e.target.value);}}
                />
                <Input 
                    type='number'
                    style={{marginBottom: 8}}
                    placeholder='Latitud'
                    value={newSensorData.lat} 
                    onChange={(e) => {handleChangeSensorData("lat", e.target.value);}}
                />
                <Input 
                    type='number'
                    style={{marginBottom: 8}}
                    placeholder='Longitud'
                    value={newSensorData.lng} 
                    onChange={(e) => {handleChangeSensorData("lng", e.target.value);}}
                />
            </Modal>

            {/* MODAL CREAR INHIBIDOR NUEVO */}
            <Modal 
                title={"Crear inhibidor nuevo"}
                style={{ maxHeight: '800px', overflowY: 'auto' }}
                open={newInhibitorModal} 
                okText={"Crear sensor"}
                onOk={createNewInhibitor} 
                okButtonProps={{ disabled: newUserLoading }}
                cancelText={"Cerrar"}
                onCancel={handleCancelCreateInhib} 
                loading={false}>
                     <Input 
                        placeholder='Nombre del inhibidor'
                        style={{marginBottom: 8}}
                        value={newInhibitorData.inhibitorname} 
                        onChange={(e) => { modifyInhibData("inhibitorname", e.target.value) }}
                    />
                    <Input 
                        placeholder='UUID del inhibidor'
                        style={{marginBottom: 8}}
                        value={newInhibitorData.id} 
                        onChange={(e) => { modifyInhibData("id", e.target.value) }}
                    />
                    <Input 
                        placeholder='Latitud'
                        type='number'
                        style={{marginBottom: 8}}
                        value={newInhibitorData.lat} 
                        onChange={(e) => { modifyInhibData("lat", e.target.value) }}
                    />
                    <Input 
                        placeholder='Longitud'
                        type='number'
                        style={{marginBottom: 8}}
                        value={newInhibitorData.lng} 
                        onChange={(e) => { modifyInhibData("lng", e.target.value) }}
                    />
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Frecuencias <Button style={{height:16, width:8}} type='primary' onClick={() => { inhibAddFreq()}}>{"+"}</Button>
                    </p>
                    {newInhibitorData.frequencies.map((e, index) => (
                            <Input 
                                key={index}
                                style={{ marginBottom: 4 }}
                                placeholder='Frecuencia'
                                value={e} 
                                onChange={(e) => { modifyInhibFreq("frequencies", index, e.target.value) }}
                            />
                    ))}
                    <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: 'bold' }}>
                        Sectores <Button style={{height:16, width:8}} type='primary' onClick={() => { inhibAddSector()}}>{"+"}</Button>
                    </p>
                    {newInhibitorData.sectors.map((e, index) => (
                        <div>
                            <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "normal", fontSize: 14 }}>
                                {`Sector ${index+1}`}
                            </p>
                            <div>
                                <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "normal", fontSize: 12 }}>
                                    Amplitud
                                </p>
                                <Input 
                                    key={index+"amp"} // Don't forget to add a key for each item in a list
                                    style={{ marginBottom: 4 }}
                                    placeholder='Amplitud'
                                    value={e.amplitude} 
                                    onChange={(e) => { modifyInhibSector("amplitude", index, e.target.value) }}
                                />
                                <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "normal", fontSize: 12 }}>
                                    Heading
                                </p>
                                <Input 
                                    key={index+"head"} // Don't forget to add a key for each item in a list
                                    style={{ marginBottom: 4 }}
                                    placeholder='Heading'
                                    value={e.heading} 
                                    onChange={(e) => { modifyInhibSector("heading", index, e.target.value) }}
                                />
                                <p style={{ marginBottom: '4px', color: '#03045E', fontWeight: "normal", fontSize: 12 }}>
                                    Rango
                                </p>
                                <Input 
                                    key={index+"rng"} // Don't forget to add a key for each item in a list
                                    style={{ marginBottom: 4 }}
                                    placeholder='Rango'
                                    value={e.range} 
                                    onChange={(e) => { modifyInhibSector("range", index, e.target.value) }}
                                />
                            </div>
                        </div>
                    ))}
            </Modal>
            
            {/*MODAL CREAR GRUPO DE SEGURIDAD */}
            <Modal title="Crear grupo de seguridad" open={isModalOpen} 
                onOk={handleOk} 
                okText={"Crear"}
                confirmLoading={createGroupLoading}
                okButtonProps={{ disabled: newGroupName === "" }}
                onCancel={handleCancel}
                cancelText={"Cancelar"}
                >
                    <Input 
                        placeholder='Nombre del grupo'
                        value={newGroupName} 
                        onChange={(e) => {setNewGroupName(e.target.value);}}
                    />
            </Modal>

            {/*MODAL EDITAR GRUPO DE SEGURIDAD */}
            <Modal title="Editar grupo de seguridad" open={editGroup.name != null} 
                onOk={() => modifyGroup()} 
                okText={"Editar"}
                confirmLoading={createGroupLoading}
                onCancel={() => setEditGroup({name: null})}
                cancelText={"Cancelar"}
                >
                    <Input 
                        placeholder='Nombre del grupo'
                        value={editGroup.name} 
                        onChange={(e) => {setEditGroup({name: e.target.value, id: editGroup.id});}}
                    />
            </Modal>

            {/*MODAL DETALLES DEL GRUPO DE SEGURIDAD */}
            <Modal 
                width={'90%'}
                title={groupDataModal.group_name === undefined ?  "" : groupDataModal.group_name }
                open={showGroupModal} 
                okText={modalActiveTab == 1 ? "Agregar usuario" : modalActiveTab == 2 ? "Agregar sensor" : "Agregar inhibidor"}
                onOk={modalActiveTab == 1 ? ()=> handleAddMemberModal() : modalActiveTab == 2 ? () => handleAddSensorModal() : ()=> handleAddInhibitorModal()} 
                cancelText={"Cerrar"}
                onCancel={handleCancelGroupDataModal} 
                loading={groupDataModal.group_name === undefined || groupDataLoading}>
                <Tabs activeKey={modalActiveTab} onChange={setModalActiveTab}>
                    <TabPane tab="Usuarios" key="1">
                        <Table  columns={userColumn} dataSource={groupDataModal.user_list} />
                    </TabPane>
                    <TabPane tab="Sensores" key="2">
                        <Table  columns={sensorColumns} dataSource={groupDataModal.sensors} />
                    </TabPane>
                    <TabPane tab="Inhibidores" key="3">
                        <Table  columns={inhibitorColumns} dataSource={groupDataModal.inhibitors} />
                    </TabPane>
                </Tabs>
            </Modal>

            {/* MODAL AGREGAR SENSOR AL GRUPO */}
            <Modal 
                title={groupDataModal.group_name === undefined ?  "" : "Agregar sensor: " + groupDataModal.group_name }
                open={addSensorModal} 
                okText={"Agregar sensor"}
                onOk={handleAddSensorToGroup} 
                okButtonProps={{ disabled: selectedUserToAdd === "" }}
                cancelText={"Cerrar"}
                onCancel={closeAddModal} 
                loading={groupDataModal.group_name === undefined}>
                    <Select
                        placeholder="Selecciona un sensor"
                        style={{ width: '100%' }}
                        onChange={handleSelectUserToAdd}
                        value={selectedUserToAdd}
                        >
                        {sensorList.map(s => {
                            if(s.group !== groupDataModal.group_name){
                                return(
                                    <Option key={s.sensorId} value={s.sensorId}>
                                        {`${s.sensorName} (${s.sensorId})`}
                                    </Option>
                                )
                            }
                        })}
                    </Select>
            </Modal>

            {/* MODAL AGREGAR INHIBIDOR AL GRUPO */}
            <Modal 
                title={groupDataModal.group_name === undefined ?  "" : "Agregar inhibidor: " + groupDataModal.group_name }
                open={addInhibitorModal} 
                okText={"Agregar inhibidor"}
                onOk={handleAddInhibitorToGroup} 
                okButtonProps={{ disabled: selectedUserToAdd === "" }}
                cancelText={"Cerrar"}
                onCancel={closeAddModal} 
                loading={groupDataModal.group_name === undefined}>
                    <Select
                        placeholder="Selecciona un inhibidor"
                        style={{ width: '100%' }}
                        onChange={handleSelectUserToAdd}
                        value={selectedUserToAdd}
                        >
                        {inhibitorList.map(inh => {
                            if(inh.group != groupDataModal.group_name){
                                return(
                                    <Option key={inh.inhibitorid} value={inh.inhibitorid}>
                                        {`${inh.inhibitorname} (${inh.inhibitorid})`}
                                    </Option>
                            )}
                        })}
                    </Select>
            </Modal>

            {/* MODAL AGREGAR USUARIO AL GRUPO */}
            <Modal 
                title={groupDataModal.group_name === undefined ?  "" : "Agregar usuario: " + groupDataModal.group_name }
                open={addUserModal} 
                okText={"Agregar usuario"}
                onOk={handleAddGroupMember} 
                okButtonProps={{ disabled: selectedUserToAdd === "" }}
                cancelText={"Cerrar"}
                onCancel={closeAddModal} 
                loading={groupDataModal.group_name === undefined}>
                    <Select
                        placeholder="Selecciona un usuario"
                        style={{ width: 200 }}
                        onChange={handleSelectUserToAdd}
                        >
                        {availableUsers.map(user => (
                            <Option key={user.id} value={user.id}>
                                {user.name}
                            </Option>
                        ))}
                    </Select>
            </Modal>
        </div>
    </div>
  );
}

export default SecurityGroups;