import React, { useState } from 'react';
import { useAuth } from "../providers/AuthContex";
import { useKeycloak } from '../providers/KeycloakContext';
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import logo from "./img/sq-logo.jpeg";

function Header() {
  const [open, setOpen] = useState(false);
  const { logout, group } = useAuth();
  const keycloak = useKeycloak();

  const navigate = useNavigate();
  const location = useLocation();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const logOut = async () => {
    try {
      logout()
      if (keycloak) {
        await keycloak.logout({})
        console.log("CHAU");
        navigate("/");
    
    } else {
        console.log('Keycloak instance is not available');
    }
    } catch (error) {
      console.log("logOut error: ", error);
    }
  }

  return (

    <header style={{ 
      display: 'flex', 
      padding: '10px',  
      height: "5vh", 
      alignItems: 'center', 
      backgroundColor: '#00B4D8', 
      flexDirection: 'row', 
      position: 'relative' 
    }}>
    <img src={logo} style={{ height: '40px' }} />
    
    <div onClick={() => { showDrawer() }} style={{ position: 'absolute', right: 15, top: 25 }}>
      <FontAwesomeIcon icon={faBars} color='#FFFFFF' />
    </div>

    <Drawer onClose={onClose} open={open}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
        
        <div>
          {group.roles.includes("Configurator") || group.roles.includes("Admin") && <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: location.pathname === "/adm/security-group" ? "#00B4D8" : "black" }} onClick={() => {onClose(); navigate("/adm/security-group")}}>
            <FontAwesomeIcon icon={faUser} color='#00B4D8' size='lg'/>
            <p style={{fontWeight: 'bold', marginLeft: 5, fontSize: 25}}>Usuarios y grupos</p>
          </a>}
          {group.roles.includes("Configurator") || group.roles.includes("Admin") && <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: location.pathname === "/map-editor" ? "#00B4D8" : "black" }} onClick={() => {onClose(); navigate('/map-editor')}}>
            <FontAwesomeIcon icon={faMapLocationDot} color='#00B4D8' size='lg'/>
            <p style={{fontWeight: 'bold', marginLeft: 5, fontSize: 25}}>Map editor</p>
          </a>}
          <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: location.pathname === "/" ? "#00B4D8" : "black" }} onClick={() => {onClose(); navigate("/")}}>
            <FontAwesomeIcon icon={faHome} color='#00B4D8' size='lg'/>
            <p style={{fontWeight: 'bold', marginLeft: 5, fontSize: 25}}>Home</p>
          </a>
          <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: "black" }}>
            <FontAwesomeIcon icon={faBell} color='#00B4D8' size='lg'/>
            <p style={{fontWeight: 'bold', marginLeft: 5, fontSize: 25}}>Alertas</p>
          </a>
          <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: "black" }}>
            <FontAwesomeIcon icon={faFile} color='#00B4D8' size='lg'/>
            <p style={{fontWeight: 'bold', marginLeft: 5, fontSize: 25}}>Reportes</p>
          </a>
        </div>

        <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, color:'#D80000'}} onClick={() => logOut()}>
          <FontAwesomeIcon icon={faSignOutAlt} color='#D80000' size='lg'/>
          <p style={{fontWeight: 'bold', marginLeft: 5, fontSize: 25, color:'#D80000' }}>Cerrar sesión</p>
        </a>
      </div>
    </Drawer>
  </header>

  );
}

export default Header;