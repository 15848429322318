import React, { useState, useEffect } from "react";
import {
  APIProvider,
  Map,
  useMap,
  AdvancedMarker,
  Polygon,
  Marker,
  GoogleMap, LoadScript, useGoogleMap
} from "@vis.gl/react-google-maps";
import { useAuth } from "../../providers/AuthContex.js";
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import MapEditorSidepanel from "./MapEditorSidepanel.js";
import SensorsServices from "../../services/SensorsServices.js";
import UserServices from "../../services/UserServices.js";
import Sensor from "../img/sensor_sin_alert.png"
import SensorWith from "../img/sensor_con_alerta.png";
import InhibitorServices from "../../services/InhibitorServices.js";
import AdminServices from "../../services/AdminServices.js";
import { Circle } from "./InhibCircle.ts";
import InhibMarker from "./InhibMarker.js";
import SensorIcons from "../SVG-icons/SensorIcons.js";

export default function MapEditorComponent() {
  const {group, saveGroup} = useAuth()
  const [messageApi, contextHolder] = message.useMessage();
  const [sensor, setSensor] = useState([]);
  const [inhibitors, setInhibitors] = useState([])
  const [selectedSensor, setSelectedSensor] = useState(null)
  const [selectedInhib, setSelectedInhib] = useState(null)
  const [loading, setLoading] = useState(false)

  const defaultProps = {
    lat: 41.37318481141028,
    lng: 2.1531474024100135,
    type: "home",
  };

  useEffect(() => {
    getSensorsAndInhib()

    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setSelectedInhib(null)
        setSelectedSensor(null)
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [])

  const onError = (message) => {
    messageApi.open({
      type: 'error',
      content: message ? message:'Algo salio mal intentelo luego',
    });
  };

  const getGroupInfo = async() => {
      try {
        const response = await UserServices.getGroupInfo()
        saveGroup(response.data)
        getSensorsAndInhib()
      } catch (error) {
        console.log("getGroupInfo error: ", error);
        onError()
      }
  }

  const getSensorsAndInhib = async() => {
    try {
      const responseSensor = await AdminServices.getSensorList()
      const responseInhib = await AdminServices.getInhibitorList()
      normalizeSensors(responseSensor.data);
      normalizeInhibitors(responseInhib.data)
    } catch (error) {
      console.log("getSensorsAndInhib error: ", error);
    }
  }

  const createNewSensor = async () => {
      setLoading(true)
      try {
          let body = {
              sensor_id: selectedSensor.id,
              manufacturer: "",
              sensor_name: selectedSensor.name,
              sensor_state: 'active',
              lat: selectedSensor.position.lat,
              long: selectedSensor.position.lng,
              recived_time: new Date().toISOString().slice(0, 19).replace('T', ' ')
          }
          const response = await SensorsServices.postSensor(body)
          setSelectedSensor(null)
          if(response.status === 200){
              // let linkBody = {
              //     "group_id": group.group_id,
              //     "sensor_id": selectedSensor.id,
              // }
              // const linkResponse = await SensorsServices.linkSensorWithGroup(linkBody)
              getGroupInfo()
          }
      } catch (error) {
          console.log("createNewSensor error: ", error);
          onError()
      } finally {
          setLoading(false)
      }
  }

  const createNewInhib = async () => {
    setLoading(true)
    try {
      let body = {
        inhibitorid: selectedInhib.id,
        inhibitorname: selectedInhib.inhibitorname,
        latitude: selectedInhib.position.lat,
        longitude: selectedInhib.position.lng,
        heading: selectedInhib.heading,
        frequencies: selectedInhib.frequencies,
        sectors: selectedInhib.sectors,
        manufacturer: selectedInhib.manufacturer
      }
      const response = await InhibitorServices.postInhibitors(body)
      setSelectedInhib(null)
      if(response.status === 200){
          // let linkBody = {
          //     "group_id": group.group_id,
          //     "inhibitor_id": selectedInhib.id,
          // }
          // const linkResponse = await InhibitorServices.linkInhibitorWithGroup(linkBody)
          getGroupInfo()
      }
    } catch (error) {
        console.log("createNewSensor error: ", error);
        if(error.response.status === 400){
          onError('Formato del UUID incorrecto')
        }else{
          onError()
        }
    } finally {
        setLoading(false)
    }
  }

  const updateSensor = async () => {
    setLoading(true)
    try {
        let body = {
            sensor_id: selectedSensor.id,
            manufacturer: "",
            sensor_name: selectedSensor.name,
            sensor_state: 'active',
            lat: selectedSensor.position.lat,
            long: selectedSensor.position.lng,
            recived_time: new Date().toISOString().slice(0, 19).replace('T', ' ')
        }
        const response = await SensorsServices.putSensor(body)
        setSelectedSensor(null)
        if(response.status === 200){
            getGroupInfo()
        }
    } catch (error) {
        console.log("modifySensor error: ", error);
        onError()
    } finally {
        setLoading(false)
    }
  }

  const updateInhib = async () => {
    setLoading(true)
    try {
      let body = {
        inhibitorid: selectedInhib.id,
        inhibitorname: selectedInhib.inhibitorname,
        latitude: selectedInhib.position.lat,
        longitude: selectedInhib.position.lng,
        heading: selectedInhib.heading,
        frequencies: selectedInhib.frequencies,
        sectors: selectedInhib.sectors,
        manufacturer: selectedInhib.manufacturer
      }
      const response = await InhibitorServices.putInhibitors(body)
      console.log(response.status);
      setSelectedInhib(null)
      if(response.status === 200){
          getGroupInfo()
      }
    } catch (error) {
        console.log("updateInhib error: ", error);
        onError()
    } finally {
        setLoading(false)
    }
  }

  const deleteSensor = async() => {
    if(selectedSensor.new){
      let sensorsLocal = [... sensor];
      const cleanMarker = sensorsLocal.filter(obj => obj.id !== selectedSensor.id);
      setSensor(cleanMarker)
      setSelectedSensor(null)
      return  
    }

    if(selectedSensor.group != null){
      onError("El sensor no debe de estar en un grupo para poder ser eliminado")
      return
    }

    setLoading(true)
    try {
        let body = {sensor_id: selectedSensor.id,}
        const response = await SensorsServices.deleteSensor(body)
        console.log("RESPONSE: ", response);
        if(response.status === 200){
            setSelectedSensor(null)
            getGroupInfo()
        }
    } catch (error) {
        console.log("deleteSensor error: ", error);
        onError()
    } finally {
        setLoading(false)
    }
  }

  const deleteInhib = async() => {
    if(selectedInhib.new){
      let inhibLocal = [... inhibitors];
      const cleanMarker = inhibLocal.filter(obj => obj.id !== selectedInhib.id);
      setInhibitors(cleanMarker)
      setSelectedInhib(null)
      return  
    }

    if(selectedInhib.group != null){
      onError("El inhibidor no debe de estar en un grupo para poder ser eliminado")
      return
    }

    setLoading(true)
    try {
      let body = {inhibitorid: selectedInhib.id,}
      const response = await InhibitorServices.deleteInhibitors(body)
      if(response.status === 200){
          setSelectedInhib(null)
          getGroupInfo()
      }
    } catch (error) {
        console.log("deleteInhib error: ", error);
        onError()
    } finally {
        setLoading(false)
    }
  }

  const getDedroneSensors = async () => {
    setLoading(true)
    try {
      const response = await AdminServices.getDedroneSensors()
      setSelectedInhib(null)
      setSelectedSensor(null)
      if(response.status === 200){
          getGroupInfo()
      }
    } catch (error) {
      onError()
    } finally {
        setLoading(false)
    }
  }

  const normalizeSensors = (data) => {
      let sensorsLocal = [];
      data.forEach((m) => {
          let row = { id: m.sensorId, name: m.sensorName, position: {lat: Number(m.latitude), lng: Number(m.longitude)}, new: false, group: m.group };
          sensorsLocal.push(row);
      });
      setSensor(sensorsLocal);
  };

  const normalizeInhibitors = (data) => {
    let inhibitorLocal = []
    data.forEach((m)=> {
      let row = {...m, position: {lat: Number(m.latitude), lng: Number(m.longitude)}, id: m.inhibitorid, new: false, group: m.group}
      inhibitorLocal.push(row)
    })
    console.log(inhibitorLocal);
    setInhibitors(inhibitorLocal)
  }

  const addNewSensor = () => {
    let sensorsLocal = [... sensor];
    const uuid = uuidv4()
    sensorsLocal.push({ id: uuid, name: "", position: {lat: Number(41.37318481141028), lng: Number(2.1531474024100135)}, new: true });
    setSelectedInhib(null)
    setSensor(sensorsLocal)
    setSelectedSensor({ id: uuid, name: "", position: {lat: Number(41.37318481141028), lng: Number(2.1531474024100135)}, new: true })
  }

  const addNewInhib= () => {
    let inhibLocal = [... inhibitors];
    const uuid = uuidv4()
    inhibLocal.push({ 
      id: uuid, 
      inhibitorname: "", 
      position: {lat: Number(41.37318481141028), lng: Number(2.1531474024100135)}, 
      heading: 0, 
      frequencies:[''],
      sectors:[{id: 1, amplitude: 0, heading: 0, range: 0}],
      manufacturer: '',
      new: true 
    });
    setSelectedSensor(null)
    setSelectedInhib({ 
      id: uuid, 
      inhibitorname: "", 
      position: {lat: Number(41.37318481141028), lng: Number(2.1531474024100135)}, 
      heading: 0, 
      frequencies:[''], 
      sectors:[{id: 1, amplitude: 0, heading: 0, range: 0}],
      manufacturer:"",
      new: true 
    })
    setInhibitors(inhibLocal)
  }

  const onSensorDragEnd = (event, markerId) => {
      const updatedMarkers = sensor.map((marker) => {
        if (marker.id === markerId) {
          setSelectedSensor({
              ...marker,
              position: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              },
            })
          return {
            ...marker,
            position: {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            },
          };
        }
        return marker;
      });
      setSensor(updatedMarkers);
  };

  const onInhibDragEnd = (event, markerId) => {
    const updatedMarkers = inhibitors.map((marker) => {
      if (marker.id === markerId) {
        setSelectedInhib({
            ...marker,
            position: {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            },
          })
        return {
          ...marker,
          position: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
        };
      }
      return marker;
    });
    setInhibitors(updatedMarkers);
  };


  const modifySensor = (newMarker) => {
    const updatedMarkers = sensor.map((marker) => {
      if (marker.id === newMarker.id) {
        const updatedId = newMarker.newId !== null && newMarker.newId !== undefined ? newMarker.newId : newMarker.id;
        setSelectedSensor({ ...newMarker, id: updatedId });
        return { ...newMarker, id: updatedId };
      }
      return marker;
    });
    setSensor(updatedMarkers);
  };

  const modifyInhib = (newMarker) => {
    const updatedMarkers = inhibitors.map((marker) => {
      if (marker.id === newMarker.id) {
        const updatedId = newMarker.newId !== null && newMarker.newId !== undefined ? newMarker.newId : newMarker.id;
        setSelectedInhib({ ...newMarker, id: updatedId });
        return { ...newMarker, id: updatedId };
      }
      return marker;
    });
    setInhibitors(updatedMarkers);
  };



  return (
    <div style={{ height: "100vh" }}>
        {contextHolder}
        <div style={{ display: 'flex', justifyContent: 'space-between',  height: '95vh' }}>
            <MapEditorSidepanel 
                onAddSensor={() => addNewSensor()}
                selectedSensor={selectedSensor}
                onModifySensor={(i)=> modifySensor(i)}
                onCreateNewSensor={()=> createNewSensor()}
                onUpdateSensor={() => updateSensor()}
                onDeleteSensor={() => deleteSensor()}
                sensorButton={sensor.some(obj => obj.new === true)}
                //-------------------------------------
                onAddInhib={()=> {addNewInhib()}}
                selectedInhib={selectedInhib}
                onModifyInhib={(i)=> modifyInhib(i)}
                onCreateNewInhib={()=> createNewInhib()}
                onUpdateInhib={()=> updateInhib()}
                onDeleteInhib={()=> deleteInhib()}
                inhibButton={inhibitors.some(obj => obj.new === true)}
                //-------------------------------------
                onGetDedroneSensors={() => getDedroneSensors()}
                loading={loading}
            />        
            <div style={{ flex: 3,  backgroundColor: '#e9ecef' }}>
                <APIProvider
                style={{ zIndex: -1000 }}
                apiKey={"AIzaSyCtVcbzEZJCQkDml1iKhhhqvUEpdGLAiSU"}
                >
                    <Map
                        mapTypeId={'satellite'}
                        defaultCenter={defaultProps}
                        defaultZoom={12}
                    >   
                        {sensor.map((marker) => (
                            <Marker
                                key={marker.id}
                                position={marker.position}
                                draggable={true}
                                onDragEnd={(event) => onSensorDragEnd(event, marker.id)}
                                onClick={() => {setSelectedInhib(null); setSelectedSensor(marker);}}
                                icon={marker.id === selectedSensor?.id ? SensorIcons.selectedSensorIcon : marker.new ? SensorIcons.newSensorIcon : SensorIcons.defaultSensorIcon}
                            />
                        ))}
                        {inhibitors.map((inhib) => (
                          InhibMarker({
                            inhib: inhib, 
                            onSelectedInhib: (inh) => {setSelectedInhib(inh); setSelectedSensor(null)}, 
                            onInhibDragEnd: (event, id) => onInhibDragEnd(event, id),
                            selectedInhib: selectedInhib
                          })
                        ))}
                    </Map>
                </APIProvider>   
            </div>
        </div>
  </div>
  );
}
