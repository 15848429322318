import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./Login.css";

import Keycloak from 'keycloak-js';
import { useAuth } from "../providers/AuthContex";
import { useKeycloak } from '../providers/KeycloakContext';
import UserServices from "../services/UserServices";
import keycloakGlobal from "../providers/KeycloakGlobal";


const keycloakConfig = {
  url: "https://keycloak.squadrone.es/",
  realm: "SPERTOO",
  clientId: 'react-client'
}

const Login = (props) => {
  const navigate = useNavigate();

  const { login, accessToken, saveGroup, logout } = useAuth();
  const keycloak = useKeycloak();
  

  useEffect(() => {
    if (!keycloak) return;

    const token =document.cookie.match(/a_token=([^;]*)/) != null ? document.cookie.match(/a_token=([^;]*)/) [1]: null
    const r_token = document.cookie.match(/r_token=([^;]*)/)  != null ? document.cookie.match(/r_token=([^;]*)/) [1]: null

    if(token === "undefined" || token === null || token === undefined || token === ""){
      login(keycloak.token,keycloak.refreshToken)
    }else{
      login(token, r_token)
    }
  }, [keycloak]);

  useEffect(()=>{
    getGroupInfo()
  },[accessToken])

  const getGroupInfo = async() => {
    try {
      const response = await UserServices.getGroupInfo()
      console.log("response: ", response.data);
      saveGroup(response.data)
      navigate("/");
    } catch (error) {
      console.log("getGroupInfo login error: ", error);
    }
  }

  return (
    <div className={"mainContainer"}>
      {/* <div className={"titleContainer"}>
        <div>Login</div>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={email}
          placeholder="Ingrese su usuario o email"
          onChange={(ev) => setEmail(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={password}
          type={"password"}
          placeholder="Enter su contraseña"
          onChange={(ev) => setPassword(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={onButtonClick}
          value={"Entrar"}
        />
      </div> */}
    </div>
  );
};

export default Login;
