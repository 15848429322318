import React, { createContext, useContext, useState, useEffect } from 'react';
import Keycloak from 'keycloak-js';

const KeycloakContext = createContext(null);
const keycloakConfig = {
    url: "https://keycloak.squadrone.es/",
    realm: "SPERTOO",
    clientId: 'react-client'
}

export const KeycloakProvider = ({ children }) => {
    const [keycloak, setKeycloak] = useState(null);

    useEffect(() => {
        const kc = new Keycloak(keycloakConfig);
        kc.init({onLoad: 'login-required'}).then(authenticated => {
            
            if(authenticated){
                setKeycloak(kc)
            }
          }).catch((error) => {console.log(error);})
        
    }, []);

    return (
        <KeycloakContext.Provider value={keycloak}>
            {children}
        </KeycloakContext.Provider>
    );
};

export const useKeycloak = () => useContext(KeycloakContext);