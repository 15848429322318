import React, { useState, useEffect } from "react";
import {
  APIProvider,
  Map,
  useMap,
  AdvancedMarker,
  Polyline,
  Marker,
} from "@vis.gl/react-google-maps";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Polygon } from "./Polygon.tsx";
import { POLYGONS } from "./encoded.ts";
import Drone from "./img/drone.png";
import Home from "./img/home.png";
import Joystick from "./img/joystick.png";
import Sensor from "./img/sensor_sin_alert.png";
import SensorWith from "./img/sensor_con_alerta.png";

import MonitoringPanel from "./MonitoringPanel.js";
import Footer from "./Footer.js";
import Mqtt from "../utils/Mqtt.js";
import mqtt from "mqtt";
import SidePanel from "./SidePanel.js";
import ZonesServices from '../services/ZonesServices.js'
import AlertServices from "../services/AlertServices.js";
import SensorsServices from "../services/SensorsServices.js";
import { useAuth } from "../providers/AuthContex.js";
import IhibitorServices from "../services/InhibitorServices.js";
import SensorIcons from "./SVG-icons/SensorIcons.js";
import InhibitorIcons from "./SVG-icons/InhibitorIcons.js";

export default function DroneMap(props) {
  const [zones, setZones] = useState(undefined);
  const navigate = useNavigate();
  const [polygons, setPolygons] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState(null);
  let [points, setPoints] = useState([]);
  const [time, setTime] = useState(Date.now());
  let [sensors, setSensors] = useState([]);
  const [socket, setSocket] = useState(null);
  const [showPanelInfo, setShowPanelInfo] = useState(false);
  const [alertActive, setAlertActive] = useState([]);
  let [radios, setRadios] = useState([]);
  let [alerts, setAlerts] = useState([]);
  let [genericSensors, setGenericSensors] = useState([]);
  let [timer, setTimer] = useState();
  const [layers, setLayers] = useState({sensors: true, zones: true})
  const [alertsToIgnore, setAlertsToIgnore] = useState([])
  const [selectedSensor, setSelectedSensor] = useState(null)
  const [inhibitors, setInhibitors] = useState([])
  const [selectedInhibitor, setSelectedInhibitor] = useState(null)
  const {group} = useAuth()

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setSelectedSensor(null)
        setSelectedInhibitor(null)
      }
    };

    window.addEventListener('keydown', handleEsc);

    // const interval = setInterval(() => {
    //   const now = Date.now();
    //   // points.forEach(event => {
    //   //   if (now - event.timestamp >= 60000) {
    //   //     console.log(`${event.alertId} no tiene actualizaciones hace 60 segundos`);
    //   //     removeAlert(event.alertId);
    //   //   }
    //   // })
    //   sensors.forEach(event => {
    //     if (now - event.timestamp >= 200) {
    //       const sensorsLocal = sensors.filter((item) => item.alertId != event.alertId);
    //       setSensors(sensorsLocal);
    //     }
    //   })
      
    //   })
    // }, 30000);
    const interval = setInterval(() => {
      const now = Date.now();
      sensors.forEach((event) => {
        if (now - event.timestamp >= 200) {
          const sensorsLocal = sensors.filter((item) => item.alertId != event.alertId);
          setSensors(sensorsLocal);
        }
      })
    }, 1000)
    

    return () => {
      window.removeEventListener('keydown', handleEsc);
      clearInterval(interval);
    };
  }, []);

  useEffect(
    (aler) => {
      // action on update of movies
      setAlerts(alerts);
      // console.log("use efect");
    },
    [alerts]
  );

  useEffect(() => {
    console.log("GROUP: ", group);
    const client = mqtt.connect(`wss://mqtt.squadrone.es:443`);
    // const client = mqtt.connect(`ws://94.24.116.70:8083`);
    // const client = mqtt.connect(`ws://${process.env.MQTT_HOST}:${process.env.MQTT_PORT_WS}`);
    getCurrentAlerts()
    getZones()
    getInhibitors(client)

    //TODO: agregar el realm
    let loc_topic = `Dedrone/${group.group_id}/Loc`  
    let rf_topic = `Dedrone/${group.group_id}/Rf`
    let alarm_info = `Dedrone/${group.group_id}/AlarmInfo`
    let end_topic = "/alert/end/topic/SPERTOO"
    // Subscribe to the topic
    client.on("connect", () => {
      console.log("Connected to MQTT broker", rf_topic);
      client.subscribe(end_topic, (err) => {
        if (!err) {
          console.log("Subscribed to end_topic");
        } else {
          console.error("Subscription error:", err);
        }
      });
      client.subscribe(rf_topic, (err) => {
        if (!err) {
          console.log("Subscribed to dedrone_alerts");
        } else {
          console.error("Subscription error:", err);
        }
      });
      client.subscribe(loc_topic, (err) => {
        if (!err) {
          console.log("Connected to MQTT broker ", loc_topic);
        } else {
          console.error("Subscription error:", err);
        }
      });
      client.subscribe(alarm_info, (err) => {
        if (!err) {
          console.log("Connected to MQTT broker", alarm_info);
        } else {
          console.error("Subscription error:", err);
        }
      });
    });

    // Handle incoming messages
    client.on("message", (topic, message) => {
      console.log("ON MQTT MESSAGE RECIVED: ", message.toString());
      const newMessage = JSON.parse(message.toString());
      if(newMessage.messageType === "sensor"){
        //MENSAJE DEL MQTT DE SENSOR
        // getSensors(true);
        if (newMessage.data.info != undefined && newMessage.data.info.status == "end") {
          console.log("END ENTER");
          removeAlert(newMessage.data.info.alertId);
          return;
        }else{
          onMessageSensor(newMessage)
        }
      }else{
        //MENSAJE DEL MQTT DE ALERTA
        if(newMessage.data === undefined){
          return
         }
        if (newMessage.data.info.status == "end") {
          console.log("END ENTER");
          removeAlert(newMessage.data.info.alertId);
          return;
        }
        normalizeRadio(newMessage.data)
      }
    });
    client.on("error", ()=> {
      getCurrentAlerts()
    })
    // Clean up on component unmount
    return () => {
      client.end();
    };
  }, [group]);

  useEffect(() => {
    getSensors(true);
    const interval = setInterval(() => {
      //checkRemove();
    }, 2000);
    setTimer(interval);
  }, [alerts]);

  const getZones = async() => {
    try {
      const response = await ZonesServices.getZones()
      normalizeZones(response.data);
    } catch (error) {
      console.log("getZones error: ", error);
    }
  }

  const getInhibitors = async (client) => {
    try {
      const response = await IhibitorServices.getInhibitors()
      console.log("DATA: ", response.data);
      response.data.forEach(element => {
        const topic = `atl/inhibitor${element.inhibitorid}/response`
        client.subscribe(topic, (err) => {
          if (!err) {
            console.log("Subscribed to: ", `atl/inhibitor${element.inhibitorid}/response`);
          } else {
            console.error("Subscription error:", err);
          }})
      });
      normalizeInhibitors(response.data)
    } catch (error) {
      console.log("getInhibitors error: ", error);
    }
  }

  const normalizeInhibitors = (data) => {
    let inhibLocal = [];
    data.forEach((m) => {
      let row = { id: m.inhibitorid, name: m.inhibitorname, lat: Number(m.latitude), lng: Number(m.longitude)};
      inhibLocal.push(row);
    });
    console.log("INHIB: ", inhibLocal);
    setInhibitors(inhibLocal);
  };

  const getSensors = async (process) => {
    // Update the document title using the browser API
    try {
      const res = await SensorsServices.getSensors()
      if (process) normalizeSensors(res.data);
    } catch (error) {
      
    }
    // axios
    //   .get("/services/sensors", {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     try {
    //       if (process) normalizeSensors(res.data);
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   })
    //   .catch((error) => {
    //     // if (error.response.status == 401) {
    //     //   navigate("/login");
    //     // }
    //   });
  };

  // console.log(props.islogin);

  const normalizePoints = (data) => {
    return;
    let markers = [];
    data.forEach((m) => {
      console.log(m);
      let row = {
        lat: Number(m[0]),
        lng: Number(m[1]),
        type: m[2],
        alertId: m[3],
      };
      markers.push(row);
    });
    setPoints(markers);
  };

  const normalizeSensors = (data) => {
    let sensorsLocal = [];
    data.forEach((m) => {
      let row = { id: m[0], name: m[1], lat: Number(m[2]), lng: Number(m[3]), selected: true };
      sensorsLocal.push(row);
    });
    console.log(sensorsLocal);
    setGenericSensors(sensorsLocal);
  };

  const normalizeZones = (data) => {
    let zones = [];
    let zone = [];
    data.forEach((element) => {
      let array = element[0].replace("POLYGON((", "").replace("))", "");
      array = array.split(",");
      zone = [];
      array.forEach((e) => {
        let coor = e.split(" ");
        coor = { lat: Number(coor[0]), lng: Number(coor[1]) };
        zone.push(coor);
      });
      let color, stroke;
      if (element[1] == "friend") {
        // verde
        color = "rgba(1,1,1,1)";
        stroke = "#0F8C03";
      }
      if (element[1] == "warning") {
        // azul
        color = "#73A4F5";
        stroke = "#03378C";
      }
      if (element[1] == "alert") {
        // rojo
        color = "red";
        stroke = "#F53014";
      }
      if (element[1] == "neutral") {
        // blanco
        color = "white";
        stroke = "#F53014";
      }
      zones.push({ path: zone, fillColor: color, strokeColor: stroke });
    });
    setPolygons(zones);
    setDefaultCenter({
      lat: 41.37318481141028,
      lng: 2.1531474024100135,
      type: "home",
    });
  };

  const checkRemove = () => {
    alerts.forEach((e) => {
      console.log("enter");
      if (e.timestamp + 60000 <= Date.now()) {
        console.log("remove");
        removeAlert(e.alertId);
      }
    });
    return;
  };

  const removeAlert = (alertId) => {
    console.log("ALERTID: ", alertId);
    console.log("SENSORS PRE REMOVE", sensors);
    const sensorsLocal = sensors.filter((item) => item.alertId != alertId);
    console.log("SENSORS POST REMOVE: ", sensorsLocal);
    setSensors(sensorsLocal);

    console.log("RADIOS PRE REMOVE: ", radios);
    const radiosLocal = radios.filter((item) => item.alertId != alertId);
    console.log("RADIOS POST REMOVE: ", radiosLocal);
    setRadios(radiosLocal);

    console.log("POINTS PRE REMOVE: ", points);
    const pointsLocal = points.filter((item) => item.alertId != alertId);
    console.log("POINTS POST REMOVE: ", pointsLocal);
    setPoints(pointsLocal);

    console.log("POINTS PRE REMOVE: ", alerts);
    const alertsLocal = alerts.filter((item) => item.info.alertId != alertId);
    console.log("POINTS POST REMOVE: ", alertsLocal);
    setAlerts(alertsLocal);
    setShowPanelInfo(alertsLocal.length > 0);
  };

  const makeAlert = (data) => {
    const alertsLocal = alerts.filter(
      (item) => item.info.alertId == data.info.alertId
    );
    if (alertsLocal.length == 0) {
      let alertObj = { alertId: data.info.alertId, timestamp: Date.now() };
      alertObj["info"] = data.info;
      alertObj["points"] = [];
      alertObj["sensors"] = [];
      let alertsL = alerts;
      alertsL.push(alertObj);
      setAlerts(alertsL);
      alerts = alertsL;

      return alertObj;
    } else {
      return alertsLocal[0];
    }
  };

  const onMessageSensor = (message) => {
    for (let index = 0; index < message.data.length; index++) {
      let data = message.data[index]
      if(alertsToIgnore.includes(data.id)){
        return
      }
      let radioS = [];

      let bearing = data.bearing;
      if (bearing != undefined && bearing != null) {
        let center = { lat: Number(data["lat"]), lng: Number(data["lng"]) };
        let obj = bearingDistance(data["lat"], data["lng"], 30, bearing);
        let newPoint = {
          lat: Number(obj["latitude"]),
          lng: Number(obj["longitude"]),
        };

        let newPoint2 = ToEastPosition(newPoint.lat, newPoint.lng, 4);
        let newPoint3 = ToWestPosition(newPoint.lat, newPoint.lng, 4);
        let celeste = "#6570eb";
        radioS.push({
          alertId: data.alertId,
          path: [center, newPoint2, newPoint3, center],
          fillColor: celeste,
          strokeColor: celeste,
          stroke: 0,
        });
      }
      setRadios(radioS);

      let changeS = sensors;
      let sensorl = sensors.filter((e) => e.id == data.id);
      let add = false;
      let sensor = null;
      if (sensorl.length > 0) {
        sensor = sensorl[0];
      } else {
        add = true;
        sensor = { id: data.id };
      }
      sensor["lat"] = data["lat"];
      sensor["lng"] = data["lng"];
      sensor['timestamp'] = new Date()
      // sensor["alertId"] = alertId;
      if (add) {
        changeS.push(sensor);
      }
      setSensors(changeS)
    }

  }

  const normalizeRadio = (data) => {
    let alertId = data.info.alertId;
    let alertObj = makeAlert(data);
    // let changeS = sensors;
    let radioS = [];
    // let sensor = null;
    for (let element in data) {
      if (element == "info" || element == "timestamp") {
        continue;
      }
      if (element != "point") {
        // let sensorl = sensors.filter((e) => e.id == element);
        // let add = false;
        // if (sensorl.length > 0) {
        //   sensor = sensorl[0];
        // } else {
        //   add = true;
        //   sensor = { id: element };
        // }
        // sensor["lat"] = data[element]["lat"];
        // sensor["lng"] = data[element]["lng"];
        // sensor["alertId"] = alertId;
        // if (add) {
        //   changeS.push(sensor);
        // }
      } else {
        let pointsS = points;
        let type =
          data[element]["type"] == undefined ? "drone" : data[element]["type"];
        let drone = pointsS.filter(
          (e) => e.alertId == alertId && e.type == type
        );
        if (drone.length > 0) {
          drone = drone[0];
          drone["lat"] = data[element]["lat"];
          drone["lng"] = data[element]["lng"];
        } else {
          drone = {
            alertId: alertId,
            lat: data[element]["lat"],
            lng: data[element]["lng"],
            type: type,
            timestamp: new Date()
          };
          pointsS.push(drone);
        }
        setPoints(pointsS);
      }

      // let bearing = data[element]["bearing"];
      // if (bearing) {
      //   let center = { lat: Number(sensor["lat"]), lng: Number(sensor["lng"]) };
      //   let obj = bearingDistance(sensor["lat"], sensor["lng"], 30, bearing);
      //   let newPoint = {
      //     lat: Number(obj["latitude"]),
      //     lng: Number(obj["longitude"]),
      //   };

      //   let newPoint2 = ToEastPosition(newPoint.lat, newPoint.lng, 4);
      //   let newPoint3 = ToWestPosition(newPoint.lat, newPoint.lng, 4);
      //   let celeste = "#6570eb";
      //   radioS.push({
      //     alertId: alertId,
      //     path: [center, newPoint2, newPoint3, center],
      //     fillColor: celeste,
      //     strokeColor: celeste,
      //     stroke: 0,
      //   });
      // }
    }

    // setSensors(changeS);
    // setRadios(radioS);
    setDefaultCenter({
      lat: 41.37318481141028,
      lng: 2.1531474024100135,
      type: "home",
    });
    setShowPanelInfo(alerts.length > 0);

    // Ajust points
    const sensorsLocal = sensors.filter((item) => item.alertId == alertId);
    alertObj["sensors"] = sensorsLocal;

    const pointsLocal = points.filter((item) => item.alertId == alertId);
    alertObj["points"] = pointsLocal;
    //setAlertActive(alerts);
  };

  const ToEastPosition = (lat, lng, eastDistance) => {
    let r_earth = 6378;
    var pi = Math.PI;
    var new_longitude =
      lng +
      ((eastDistance / r_earth) * (180 / pi)) / Math.cos((lat * pi) / 180);
    return { lat: Number(lat), lng: Number(new_longitude) };
  };

  const ToWestPosition = (lat, lng, westDistance) => {
    let r_earth = 6378;
    var pi = Math.PI;
    var new_longitude =
      lng -
      ((westDistance / r_earth) * (180 / pi)) / Math.cos((lat * pi) / 180);
    return { lat: Number(lat), lng: Number(new_longitude) };
  };

  const toRad = (degrees) => {
    return (degrees * Math.PI) / 180;
  };

  const toDeg = (radians) => {
    return (radians * 180) / Math.PI;
  };
  const bearingDistance = (lat, lon, radius, bearing) => {
    let lat1Rads = toRad(lat);
    let lon1Rads = toRad(lon);
    const R_KM = 6371; // radius in KM
    let d = radius / R_KM; //angular distance on earth's surface

    let bearingRads = toRad(bearing);
    let lat2Rads = Math.asin(
      Math.sin(lat1Rads) * Math.cos(d) +
        Math.cos(lat1Rads) * Math.sin(d) * Math.cos(bearingRads)
    );

    let lon2Rads =
      lon1Rads +
      Math.atan2(
        Math.sin(bearingRads) * Math.sin(d) * Math.cos(lat1Rads),
        Math.cos(d) - Math.sin(lat1Rads) * Math.sin(lat2Rads)
      );

    return {
      latitude: toDeg(lat2Rads),
      longitude: toDeg(lon2Rads),
    };
  };

  const normalizeAlertsShow = () => {
    let nAlerts = [];
    alerts.forEach((a) => {
      if (a.info.sensorType == "aeroscope") {
        let found = nAlerts.filter(
          (e) => e.info.serialNumber == a.info.serialNumber
        );
        if (found.length > 0) {
          let alert = found[0];
          alert.points.push(...a.points);
        } else {
          nAlerts.push(JSON.parse(JSON.stringify(a)));
        }
      } else {
        nAlerts.push(JSON.parse(JSON.stringify(a)));
      }
    });
    return nAlerts;
  };

  const getInfoPanel = () => {
    if (showPanelInfo) {
      return (
        <MonitoringPanel
          data={normalizeAlertsShow()}
          onClose={() => setShowPanelInfo(false)}
        />
      );
    }
    return <div />;
  };

  const defaultProps = {
    lat: 41.37318481141028,
    lng: 2.1531474024100135,
    type: "home",
  };

  const getCurrentAlerts = async () => {
    try {
      const res = await AlertServices.getCurrentAlerts()
      console.log("RES RESDIS: ", res.data);
      setPoints([{
        alertId: res.data[0].data.info.alertId, 
        lat: res.data[0].data.point.lat, 
        lng: res.data[0].data.point.lng, 
        type: res.data[0].data.point.type,
        timestamp: new Date()
      }]);
    } catch (error) {
      console.log("getCurrentAlerts error: ", error);
    }
  }

  const tapEvent = async (event) => {
    try {
      const res = await AlertServices.getAlertById(event.alertId)
      setAlertActive(res.data);
      setShowPanelInfo(true);
    } catch (error) {
      console.log("tapEvent error: ",error);
    }
  };
  // console.log("ahora" + props.authenticated);
  //if (props.authenticated == null || props.authenticated == false) {
  // return <div />
  //}

  const onSelectSensor = (sensor) => {
    setSelectedInhibitor(null)
    setSelectedSensor(sensor)
    // const updatedItems = genericSensors.map(item =>
    //   item.id === sensor.id ? {... sensor, selected: !sensor.selected} : item
    // );
    // console.log(updatedItems);
    // setGenericSensors(updatedItems)
  }

  const onSelectInhibitor = (inhib) => {
    setSelectedSensor(null)
    setSelectedInhibitor(inhib)
    // const updatedItems = genericSensors.map(item =>
    //   item.id === sensor.id ? {... sensor, selected: !sensor.selected} : item
    // );
    // console.log(updatedItems);
    // setGenericSensors(updatedItems)
  }

  const handleAlertTracking = (id) => {
    if(alertsToIgnore.includes(id)){
      let alertsList = [...alertsToIgnore]
      alertsList = alertsList.filter(item => item !== id);
      setAlertsToIgnore(alertsList)
    } else {
      let alertsList = [...alertsToIgnore]
      alertsList.push(id)
      setAlertsToIgnore(alertsList)
    }
  }

  return (
    <div style={{ height: "90vh", display: 'flex', flexDirection: 'column', overflow: "hidden" }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, overflow: 'hidden' }}>
          <SidePanel 
            sensors={genericSensors} 
            alerts={alerts} 
            inhibitors={inhibitors}
            onSelectSensor={(item) => onSelectSensor(item)} 
            onSelectInhib={(item) => onSelectInhibitor(item)}
            onHandleAlertTracking={(id) => {handleAlertTracking(id)}}
            selectedSensor={selectedSensor}
            selectedInhibitor={selectedInhibitor}
          />
      <div style={{ flex: 3, backgroundColor: '#e9ecef', height: '100%' }}>
        <APIProvider
          style={{ zIndex: -1000 }}
          apiKey={"AIzaSyCtVcbzEZJCQkDml1iKhhhqvUEpdGLAiSU"}
        >
            {polygons && points && (
              <Map
                mapTypeId={'satellite'}
                defaultCenter={defaultCenter ? defaultCenter : defaultProps}
                defaultZoom={12}
              >
                {layers.zones && polygons.map((element) => (
                  <Polygon
                    strokeWeight={2}
                    encodedPaths={[element.path]}
                    keyValue={1}
                    fillColor={element.fillColor}
                    strokeColor={element.strokeColor}
                  />
                ))}
                {points.map((element) => {
                  let icon = Drone;
                  if (element.type === "home") icon = Home;
                  else if (element.type === "remote") icon = Joystick;

                  return (
                    <Marker
                      position={element}
                      icon={icon}
                      onClick={(e) => console.log(element)}
                    />
                  );
                })}
                {layers.sensors && sensors.map((element) => (
                  <Marker zIndex={999} position={element} icon={SensorIcons.alertSensorIcon} />
                ))}
                {layers.sensors && genericSensors.map((element) => (
                  <Marker
                    onClick={() => { onSelectSensor(element); }}
                    zIndex={selectedSensor !== null && selectedSensor.id === element.id ? 999 : 998}
                    position={element}
                    icon={selectedSensor !== null && selectedSensor.id === element.id ? SensorIcons.selectedSensorIcon : SensorIcons.defaultSensorIcon}
                  />
                ))}
                {inhibitors.map((element) => (
                  <Marker
                    onClick={() => { onSelectInhibitor(element); }}
                    zIndex={selectedInhibitor !== null && selectedInhibitor.id === element.id ? 999 : 998}
                    position={element}
                    icon={selectedInhibitor !== null && selectedInhibitor.id === element.id ? InhibitorIcons.selectedInhibIcon : InhibitorIcons.defaultInhibIcon}
                  />
                ))}
                {radios.map((element) => (
                  <Polygon
                    strokeWeight={2}
                    encodedPaths={[element.path]}
                    keyValue={1}
                    fillColor={element.fillColor}
                    strokeColor={element.strokeColor}
                  />
                ))}
              </Map>
            )}
        </APIProvider>   
      </div>
      </div>
    <Footer layers={layers} onSetLayers={(e) => setLayers(e)} />
  </div>
  
  );
}
